import React, { cloneElement } from 'react';
import clsx from 'clsx';
import { useField } from 'remix-validated-form';
import { Input } from './ui/input';
import { Label } from './ui/label';

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  label?: string;
  name: string;
  icon?: React.ReactNode;
  inputIcon?: React.ReactElement<{ className?: string }>;
}

export function FormInput(props: InputProps) {
  const {
    className,
    labelClassName,
    inputClassName,
    label,
    name,
    defaultValue,
    icon,
    inputIcon,
    type,
    ...rest
  } = props;

  const { error, getInputProps } = useField(name, {
    validationBehavior: {
      initial: 'onSubmit',
    },
  });

  return (
    <div className={clsx('flex w-full flex-col gap-2', className)}>
      {label && (
        <div className="flex items-center h-6 gap-2">
          <Label htmlFor={name} className={labelClassName}>
            {label}
          </Label>
          {icon}
        </div>
      )}

      <div className="relative">
        <Input
          id={name}
          name={name}
          defaultValue={defaultValue}
          className={inputClassName}
          type={type}
          {...getInputProps({ id: name, type: type, ...rest })}
        />
        {inputIcon &&
          cloneElement(inputIcon, {
            className:
              'absolute top-[50%] right-4 -translate-y-1/2 ' +
              (inputIcon?.props?.className || ''),
          })}
      </div>

      {error && <Label className="text-input-error">{error}</Label>}
    </div>
  );
}
